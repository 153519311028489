import React, {useContext} from 'react';
import Icon from '../Icon/Icon';
import {UserContext} from '../Context/ApplicationContextProvider';
import {langSiteDir} from '../../common/baseData';

function UserDataMobile(props) {
  const {
    name,
    isAuthorized,
    compareItemIds,
    wishlistItemIds,
    cashback,
    basketCount,
  } = {...useContext(UserContext)};

  return (
    <>
      {
        Array.isArray(wishlistItemIds) && wishlistItemIds.length > 0 && (
          <span className="relative justify-center items-center w-10 h-10 hidden desktop:flex">
            <Icon name={'wishlist-icon'}
                  className="h-6 w-6"
                  onClick={() => {
                    if (isAuthorized && Array.isArray(wishlistItemIds) &&
                      wishlistItemIds.length) {
                      window.location.href = `${langSiteDir}personal/wishlist/`;
                    }
                  }}/>
            <span className="absolute right-0 top-0 bg-red-info w-4 h-4
                  flex items-center justify-center text-[11px]
                  text-white rounded-10">
                {wishlistItemIds.length}
            </span>
          </span>
        )
      }
      {
        Array.isArray(compareItemIds) && compareItemIds.length > 0 && (
          <span
            className="relative justify-center items-center w-10 h-10 hidden desktop:flex">
            <Icon name={'compare-icon'}
                  className="h-[27px] w-[35px]"
                  onClick={() => {
                    if (isAuthorized && Array.isArray(compareItemIds) &&
                      compareItemIds.length) {
                      window.location.href = `${langSiteDir}personal/compare/`;
                    }
                  }}/>
            <span
              className="absolute right-0 top-0 bg-red-info w-4 h-4 flex items-center justify-center text-[11px] text-white rounded-10">
                {compareItemIds.length}
            </span>
          </span>
        )
      }
      {
        isAuthorized && (
          <div id="app-inbox" className={'app-inbox w-10 h-10 flex items-center justify-center'}/>
        )
      }
      <a href={`tel:${'0800204402'}`}
         className={'mob-call-icon relative flex justify-center items-center w-10 h-10'}>
        <Icon name="phone-icon"
              className="w-[23px] h-[22px] pointer-events-none"/>
      </a>
    </>
  );
}

// Функция для получения только блока кешбэка
export function CashbackBlock() {
  const {isAuthorized, cashback} = useContext(UserContext);

  if (!isAuthorized || Number(cashback) <= 0) {
    return null; // Возвращаем null, если кешбэк не доступен
  }

  return (
    <span
      className="flex flex-nowrap gap-[5px] w-auto h-5 bg-grey-100 rounded-10 px-1.5 items-center desktop:hidden">
      <Icon name={'cashback-icon'} className="h-[11px] w-[15px]"/>
      <span className="text-[11px]">{cashback}</span>
    </span>
  );
}

export default UserDataMobile;