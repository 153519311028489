import './MobileCitySwitch.css';
import React, {useEffect, useRef, useState} from 'react';
import ClearIcon from './images/close.svg';
import {
  axiosInstance,
  apiBaseUrl,
} from '../../../common/baseData';

function MobileCitySwitch(props) {
  const [searchText, setSearchText] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);

  const inputRef = useRef();

  const config = {headers: {'X-Requested-With': 'XMLHttpRequest'}};

  // filter city list when user input search text
  useEffect(() => {
    if (!searchText.length) setFilteredCities(props.cities);
    else {
      let cities = [];

      props.cities.map(city => {
        // check search text input
        if (city.hasOwnProperty('title') && city.title.length &&
            city.title.toLowerCase().indexOf(searchText.toLowerCase()) !==
            -1) cities.push(city);
      });

      setFilteredCities(cities);
    }
  }, [searchText]);

  async function onSwitch(city, reload = false) {
    let cityId = Number(city.id);

    let response = await request(
      {action: 'cityChange', id: cityId, path: window.location.pathname});

    if (response && response.status) {
      // Очистка города доставки выбранного на странице товара
      window.localStorage.removeItem('delivery_city');

      if (response.link && typeof response.link === 'string') {
        window.location.href = response.link;
      } else {
        window.location.href = city.link;
      }
    }
  }

  /**
   * Sends a request to the server using axios.
   *
   * @param {Object} data - The data to be sent in the request.
   * @returns {Promise} A Promise that resolves to the server response data if successful, or null if an error occurs.
   */
  function request(data) {
    let url = `${apiBaseUrl}region/${data.action}`;
    return new Promise(resolve => {
      axiosInstance.post(url, data).
      then(response => (resolve(response.data))).
      catch(reason => (resolve(null)));
    });
  }

  // Event: user input search text
  function onSearch(event) {
    setSearchText(event.target.value.trim());
  }

  // Event: user request clear search text
  function onClearSearch(event) {
    setSearchText('');
    focusOnInput();
  }

  function focusOnInput() {
    if (inputRef.current !== null && inputRef.current instanceof HTMLElement) {
      inputRef.current.focus();
    }
  }

  const placeholder = props.hasOwnProperty('messages') &&
  props.messages.hasOwnProperty('CITY_SWITCH_SEARCH') ?
      props.messages['CITY_SWITCH_SEARCH'] : 'City search';

  const filteredList = filteredCities.map(city => {
    let active =
      city.id === props.actualCity?.id &&
      props.actualCity?.checked === true;
    return <li key={city.id} className={`${active ? 'active' : ''}`}
               onClick={event => {
                 event.preventDefault();
                 onSwitch(city, true)
               }}>
      <span>{city.title}</span>
    </li>;
  });

  return (
      <div className={'mobile-city-switch'}>
        <div className={'mobile-city-switch-search'}>
          <form onSubmit={event => event.preventDefault()}>
            <input ref={inputRef}
                   type={'text'}
                   autoComplete={'off'}
                   onChange={onSearch}
                   placeholder={placeholder}
                   value={searchText}/>
            <img src={ClearIcon}
                 alt={'Clear Icon'}
                 title={'Clear Icon'}
                 width={'16px'}
                 height={'16px'} onClick={onClearSearch}/>
          </form>
        </div>
        <ul className={'mobile-city-switch-list'}>{filteredList}</ul>
      </div>
  );
}

export default MobileCitySwitch;