import React, { useState } from 'react';

function PromoBannerCorner(props) {
  // Получаем текущий путь из URL
  const currentURL = window.location.pathname;

  // Проверяем на наличие кеша в localStorage
  const cachedBannerTime = localStorage.getItem('promoBannerClosed');

  // Проверяем, есть ли текущий урл в списке, где не следует отображать баннер
  const isHiddenLink = props.hide_links?.some((link) => currentURL.includes(link));

  // Проверяем, истёк ли день с момента закрытия баннера
  const isDayExpired = cachedBannerTime
    ? Date.now() - new Date(cachedBannerTime).getTime() > 24 * 60 * 60 * 1000
    : true;

  // Определяем, нужно ли показывать баннер
  const shouldShowBannerInitially = !cachedBannerTime || isDayExpired;
  const [isBannerVisible, setIsBannerVisible] = useState(
    shouldShowBannerInitially && !isHiddenLink && props.page !== 'Product'
  );

  // Закрытие баннера
  const closeBanner = (e) => {
    e.preventDefault();
    localStorage.setItem('promoBannerClosed', new Date().toISOString()); // Устанавливаем текущую дату
    setIsBannerVisible(false); // Обновляем состояние, чтобы скрыть баннер
  };

  // Закрытие баннера при переходе на баннерную ссылку
  const handleBannerClick = () => {
    localStorage.setItem('promoBannerClosed', new Date().toISOString()); // Устанавливаем текущую дату
  };

  return (
    isBannerVisible && (
      <div className="fixed right-5 bottom-[70px] z-[3] desktop:bottom-5 desktop:z-[1066]">
        <a
          href="#"
          className="close-modal promo-banner-corner !top-[-20px] !right-[-15px] !w-[30px] !h-[30px] bg-white border border-solid border-blue-primary rounded-full hover:!border-blue-primary"
          data-dismiss="modal"
          onClick={closeBanner}
        ></a>
        <div className="promo-popup-banner max-w-[280px] max-h-[130px] overflow-hidden min-1024:max-w-[360px] min-1024:max-h-[168px]">
          <a
            className="flex h-full border-0"
            href={props.link}
            onClick={handleBannerClick}
          >
            <img
              src={props.image_src}
              alt={props.title}
              className="object-cover w-full h-full"
            />
          </a>
        </div>
      </div>
    )
  );
}

export default PromoBannerCorner;